/**
 *  Name : Nevil Kristi
 *  Date : 18-02-2022
 */
/** tool list-Random Name */

//TOOL LIST
export const FETCH_TOOL_LIST = "FETCH_TOOL_LIST";
export const FETCH_TOOL_LIST_SUCCESS = "FETCH_TOOL_LIST_SUCCESS";
export const FETCH_TOOL_LIST_ERROR = "FETCH_TOOL_LIST_ERROR";

export const FETCH_TOOL_OPTIONS = "FETCH_TOOL_OPTIONS";
export const FETCH_TOOL_OPTIONS_SUCCESS = "FETCH_TOOL_OPTIONS_SUCCESS";
export const FETCH_TOOL_OPTIONS_ERROR = "FETCH_TOOL_OPTIONS_ERROR";

export const DELETE_TOOL_LIST = "DELETE_TOOL_LIST";
export const DELETE_TOOL_LIST_SUCCESS = "DELETE_TOOL_LIST_SUCCESS";
export const DELETE_TOOL_LIST_ERROR = "DELETE_TOOL_LIST_ERROR";

export const DELETE_TOOL_OPTIONS = "DELETE_TOOL_OPTIONS";
export const DELETE_TOOL_OPTIONS_SUCCESS = "DELETE_TOOL_OPTIONS_SUCCESS";
export const DELETE_TOOL_OPTIONS_ERROR = "DELETE_TOOL_OPTIONS_ERROR";

export const SAVE_TOOL_LIST = "SAVE_TOOL_LIST";
export const SAVE_TOOL_LIST_SUCCESS = "SAVE_TOOL_LIST_SUCCESS";
export const SAVE_TOOL_LIST_ERROR = "SAVE_TOOL_LIST_ERROR";
export const SET_TOOL_NAME = "SET_TOOL_NAME";
export const SET_WHEEL_OPTIONS = "SET_WHEEL_OPTIONS";

export const GET_POLL_LIST = "GET_POLL_LIS";
export const GET_POLL_LIST_SUCCESS = "GET_POLL_LIS_SUCCESS";
export const GET_POLL_LIST_ERROR = "GET_POLL_LIS_ERROR";
export const CLEAR_STORE = "CLEAR_STORE";
export const GET_SYSTEM_CONFIGURATION_SUCCESS =
  "GET_SYSTEM_CONFIGURATION_SUCCESS";
export const GET_SYSTEM_CONFIGURATION_ERROR = "GET_SYSTEM_CONFIGURATION_ERROR";
export const CLEAR_NAME = "CLEAR_NAME";
export const RESET = "RESET";
