import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import Layout from "./Layout/reducer";
import Home from "../modules/home/store/reducer";
import Game from "../modules/Games/store/reducer";
import GameDetail from "modules/GameDetail/store/reducer";
import Note from "modules/MyList/store/reducer";
import Icebreaker from "./IceBreaker/reducers";
import Tool from "modules/Tools/store/reducer";
import Filters from "./filter/reducers";

const rootReducer = combineReducers({
  Auth,
  Layout,
  Home,
  Game,
  GameDetail,
  Note,
  Icebreaker,
  Tool,
  Filters,
});

export default rootReducer;
