import {
  PAGE_LINK_LOADING,
  PAGE_LINK,
  GAME_DETAIL_LOADING,
  GAME_DETAIL,
} from "./actionTypes";

const initialState = {
  PageLink: {
    GamePageLinkLoading: false,
    GamePageLink: null,
  },
  GameDetail: {
    GameDetailLoading: false,
    GameDetail: null,
  },
};

const gameDetailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PAGE_LINK_LOADING:
      return {
        ...state,
        PageLink: {
          ...state.PageLink,
          GamePageLinkLoading: payload,
        },
      };
    case PAGE_LINK:
      return {
        ...state,
        PageLink: {
          ...state.PageLink,
          GamePageLink: payload,
        },
      };
    case GAME_DETAIL_LOADING:
      return {
        ...state,
        GameDetail: {
          ...state.GameDetail,
          GameDetailLoading: payload,
        },
      };
    case GAME_DETAIL:
      return {
        ...state,
        GameDetail: {
          ...state.GameDetail,
          GameDetail: payload,
        },
      };
    default:
      return state;
  }
};

export default gameDetailReducer;
