import { ADD_UPDATE_NOTE, FETCH_NOTE, FETCH_NOTE_SUCCESS } from "./actionTypes";

const initialState = {
  loading: false,
  notes: [],
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: action.payload.rows !== undefined ? action.payload.rows : [],
      };
    case ADD_UPDATE_NOTE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default noteReducer;
